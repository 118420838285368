<template>
  <div>
    <div class="wy-steps-buttons unselectable" v-if="showStepsNavigator">
      <div class="wy-back" @click="backStep">{{ $t('back') }}</div>
      <div class="wy-next" @click="nextStep">{{ $t('next') }}</div>
    </div>

    <div class="wy-top-space"></div>

    <CAlert color="danger text-center" v-if="!_.isEmpty(errorMessage)" @update:show="errorMessage = ''">
      {{ errorMessage }}
    </CAlert>

    <div v-if="step === 1" class="wy-customer-step">
      <div class="wy-label">{{ $t('select_the_customer') }}</div>

      <div class="wy-search-input-container">
        <input
            class="form-control full-width"
            :placeholder="$t('type_here_to_search_for_a_customer')"
            type="text"
            v-model="customerSearchKey"
        />
        <button class="wy-clear-button" @click="clearSearch" v-if="customerSearchKey !== ''">
          <span class="wy-icon-clear-input"></span>
        </button>
      </div>

      <div class="wy-customers-list">
        <div class="wy-customers-list-item" :class="{ 'selected': customerId === 0 }" @click="unsetCustomer()">
          {{ $t('cash_customer') }}
        </div>
        <div class="wy-customers-list-item" v-for="(customer, customerIndex) in finalCustomers"
             :key="`customer_item_${customerIndex}`"
             :class="{ 'selected': customerId === customer.id }"
             @click="setCustomer(customer)">
          {{ customer.full_name }}
        </div>
      </div>
    </div>

    <div v-if="step === 2" class="wy-product-step">
      <div class="wy-label">{{ $t('select_the_product') }}</div>
      <div class="wy-products-list">
        <div v-for="(product, productIndex) in products"
             :key="`product_${productIndex}`"
             @click="setProduct(product)"
             :class="{ 'selected': productId === product.id }">
          <span :style="{ 'background-color': product.color }"></span>
          <div>{{ product.name }}</div>
        </div>
      </div>
    </div>

    <div v-if="step === 3" class="wy-product-quantity-step">

      <div class="wy-label">{{ $t('number_of_liters') }}</div>

      <div class="wy-quantity unselectable">{{ quantity }}</div>

      <div class="wy-keyboard unselectable">
        <div @click="addToQuantity(1)">1</div>
        <div @click="addToQuantity(2)">2</div>
        <div @click="addToQuantity(3)">3</div>
        <div @click="addToQuantity(4)">4</div>
        <div @click="addToQuantity(5)">5</div>
        <div @click="addToQuantity(6)">6</div>
        <div @click="addToQuantity(7)">7</div>
        <div @click="addToQuantity(8)">8</div>
        <div @click="addToQuantity(9)">9</div>
        <div @click="addToQuantity(0)">0</div>
        <div @click="addToQuantity('.')">.</div>
        <div @click="backDeleteInQuantity()"
             v-long-press="500"
             @long-press-start="longPressBackDeleteInQuantity">
          <span class="wy-icon-backspace" style="font-size: 40px;"></span>
        </div>
      </div>

    </div>

    <div v-if="step === 4" class="wy-payment-method-step">
      <div class="wy-label">{{ $t('select_payment_method') }}</div>
      <div class="wy-payment-methods-list">
        <div v-for="(paymentMethodItem, paymentMethodItemIndex) in paymentMethods"
             :key="`payment_method_${paymentMethodItemIndex}`"
             @click="setPaymentMethod(paymentMethodItem)"
             :class="{ 'selected': paymentMethod === paymentMethodItem.value }">
          <span>
            <span :class="paymentMethodItem.icon"></span>
          </span>
          <div>{{ paymentMethodItem.label }}</div>
        </div>
      </div>
    </div>

    <div v-if="step === 5" class="wy-saved-step">
<!--      <CAlert color="success text-center" v-if="!_.isEmpty(doneMessage)" @update:show="doneMessage = ''">-->
<!--        {{ doneMessage }}-->
<!--      </CAlert>-->

      <div>
        <strong>{{ $t('date') }}:</strong> {{ invoice_date }}
      </div>
      <div>
        <strong>{{ $t('invoice_number') }}:</strong> {{ invoice_number }}
      </div>
      <div>
        <strong>{{ $t('the_customer') }}:</strong> {{ customer.full_name }}
      </div>
      <div>
        <strong>{{ $t('product') }}:</strong> {{ product.name }}
      </div>
      <div>
        <strong>{{ $t('quantity') }}:</strong> {{ quantity }} {{ $t('liters') }}
      </div>
      <div v-if="paymentMethod">
        <strong>{{ $t('payment_method') }}:</strong> {{ $t(paymentMethod) }}
      </div>

      <div class="wy-print-button unselectable" @click="printInvoice">{{ $t('print_invoice') }}</div>

      <div class="wy-print-button unselectable" @click="newInvoice">{{ $t('new_invoice') }}</div>
    </div>



  </div>
</template>

<script>

import {getData, insertInvoice} from "../../api/workerDashboard";
import moment from "moment";
import {Buffer} from "buffer";

export default {
  name: 'NewWorkerInvoice',
  data() {
    return {
      step: 1,
      customers: [],
      products: [],
      settings: [],
      paymentMethods: [
        {
          label: this.$t('cash'),
          value: 'cash',
          icon: 'wy-icon-cash',
        },
        {
          label: this.$t('mada'),
          value: 'mada',
          icon: 'wy-icon-mada',
        },
        {
          label: this.$t('credit_card'),
          value: 'credit_card',
          icon: 'wy-icon-credit-card',
        },
      ],

      searchCustomer: '',
      errorMessage: '',
      doneMessage: '',
      showStepsNavigator: true,
      invoice: {},

      customerId: 0,
      productId: '',
      product: '',
      quantity: 0,
      paymentMethod: '',
      invoice_date: '',
      invoice_date_timestamp: '',
      invoice_number: '',
      customer: '',
      customerSearchKey: '',
    };
  },
  mounted() {
    this.getCustomer();
  },
  computed: {
    finalCustomers() {

      let customers = this.customers;

      if (this.customerSearchKey !== '') {
        return this._.filter(customers, (item) => {
          if (item.full_name.includes(this.customerSearchKey)) {
            return true;
          }
          return false;
        });
      }
      return customers;
    },
  },
  watch: {
    customerSearchKey() {
      this.customerId = 0;
    },
    productId() {
      this.errorMessage = '';
    },
    quantity() {
      this.errorMessage = '';
    },
    paymentMethod() {
      this.errorMessage = '';
    },
    '$store.state.workerIsOffline': function (workerIsOffline) {
      if (!workerIsOffline) {
        this.getCustomer();
      }
    },
    step(step) {
      this.errorMessage = '';
      if (step === 5) {
        this.invoice_date = moment().utc().locale('en').format('YYYY-MM-DD HH:mm:ss');
        this.invoice_date_timestamp = moment().utc().locale('en').format();
        this.invoice_number = `${this.$store.state.user.id}${ moment().utc().locale('en').unix()}`;

        this.invoice = {
          customer_id: this.customerId,
          gas_station_id: this.$store.state.user.gas_stations[this.$store.state.user.gas_stations.length - 1]['id'], // todo
          payment_method: this.paymentMethod,
          invoice_date: this.invoice_date,
          invoice_number: this.invoice_number,
          products: [
            {
              product_id: this.productId,
              quantity: this.quantity,
            }
          ],
        };

        if (this.$store.state.workerIsOffline) {
          this.showStepsNavigator = false;
          this.$store.commit('addWorkerOfflineInvoice', this.invoice);
        } else {
          insertInvoice(this, this.invoice, () => {
            this.showStepsNavigator = false;
            this.doneMessage = this.$t('the_invoice_has_been_saved');
          });
        }
      }
    },
  },
  methods: {
    newInvoice() {
      this.step = 1;
      this.errorMessage = '';
      this.doneMessage = '';
      this.showStepsNavigator = true;
      this.invoice = {};
      this.customerId = 0;
      this.productId = '';
      this.quantity = 0;
      this.paymentMethod = '';
      this.product = '';
      this.invoice_date = '';
      this.invoice_number = '';
      this.customer = '';
      this.total = '';
      this.totalVat = '';
    },
    getCustomer() {
      getData(this, this.searchCustomer, (response) => {
        this.customers = response.customers;
        this.products = response.products;
        this.settings = response.settings;
      });
    },
    validateProduct(callback) {
      if (!this.productId) {
        this.errorMessage = this.$t('you_must_select_the_product')
      } else {
        callback()
      }
    },
    validateQuantity(callback) {
      if (Number(this.quantity) === 0) {
        this.errorMessage = this.$t('you_must_enter_the_number_of_liters')
      } else {
        callback()
      }
    },
    validatePaymentMethod(callback) {
      if (!this.paymentMethod) {
        this.errorMessage = this.$t('you_must_select_the_payment_method')
      } else {
        callback()
      }
    },
    doNextStep() {
      this.step += 1;
    },
    nextStep() {
      if (this.step === 1) { // client
        this.doNextStep();
      } else if (this.step === 2) { // product
        this.validateProduct(() => {
          this.doNextStep();
        });
      } else if (this.step === 3) { // quantity
        this.validateQuantity(() => {
          if (this.customerId.toString() !== '0') {
            this.step += 2;
          } else {
            this.doNextStep();
          }
        });
      } else if (this.step === 4) { // payment method
        this.validatePaymentMethod(() => {
          this.doNextStep();
        });
      }
    },
    backStep() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    setProduct(product) {
      this.product = product;
      this.productId = product.id;
      this.nextStep();
    },
    setCustomer(customer) {
      this.customer = customer;
      this.customerId = customer.id;
      this.nextStep();
    },
    unsetCustomer() {
      this.customer = '';
      this.customerId = 0;
      this.nextStep();
    },
    addToQuantity(text) {
      let finalQuantity = this._.clone(this.quantity);

      finalQuantity = finalQuantity.toString();

      let go = true;

      if (text === '.' && finalQuantity.includes('.')) {
        go = false;
      }

      if (finalQuantity.includes('.') && finalQuantity.split('.')[1].length >= 4) {
        go = false;
      }

      if (go) {
        if (finalQuantity === '0') {
          finalQuantity = '';
        }
        finalQuantity = `${finalQuantity}${text}`;
      }

      if (finalQuantity === '.') {
        finalQuantity = '0.';
      }

      if (Number(finalQuantity) <= 99999) {
        this.quantity = finalQuantity;
      }
    },
    clearSearch() {
      this.customerSearchKey = '';
    },
    backDeleteInQuantity() {
      if (this.quantity.length > 1) {
        this.quantity = this.quantity.substring(0, this.quantity.length - 1);
      } else {
        this.quantity = 0;
      }
    },
    longPressBackDeleteInQuantity() {
      this.quantity = 0;
    },
    setPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod.value;
      this.nextStep();
    },
    printInvoice() {
      const settings = this._.cloneDeep(this.settings);
      settings.logo_image_base64 = (settings.logo_image_base64.replace(/^data:image\/[a-z]+;base64,/, "")).trim();
      const totalPerLiter = (Number(this.product.consumer_price) + ((Number(this.product.consumer_price) / 100) * Number(this.settings.vat_percentage.replace('%', '')))).toFixed(2);
      const total = (totalPerLiter * Number(this.quantity)).toFixed(2);
      this.total = total;
      const total_vat = (((Number(this.product.consumer_price) / 100) * Number(this.settings.vat_percentage.replace('%', ''))) * Number(this.quantity)).toFixed(2);
      this.totalVat = total_vat;
      const x = 'x';
      const product_details_1 = `${totalPerLiter} ريال ${x} ${this.quantity} لتر`;
      const product_details_2 = `تشمل ${this.settings.vat_percentage} ضريبة القيمة المضافة`;

      const literText = 'لتر';
      const sarText = 'ريال';
      let product_align_details_1 = `${this.product.name} - ${this.quantity} ${literText}            ${totalPerLiter} ${sarText}`;
      const product_align_details_2 = `تشمل ${this.settings.vat_percentage} ضريبة القيمة المضافة`;

      const finalData = ({
        settings,
        text: `
\n
رقم الفاتورة
${this.invoice_number}
ــــــــــــــــــــــ
${moment.utc(this.invoice_date).local().format('dddd DD-MMM-YYYY, hh:mm A')}
ــــــــــــــــــــــ
رقم السجل التجاري
${this.settings.commercial_register_number}
رقم تسجيل القيمة المضافة
${this.settings.vat_registration_number}
ــــــــــــــــــــــ
${this.product.name}
\n
${product_details_1}
${product_details_2}
\n
الإجمالي
${total} ريال
ــــــــــــــــــــــ
العنوان
${this.settings.building_number} ـ ${this.settings.street}
${this.settings.district} ـ ${this.settings.city}
\n
الهاتف
${this.settings.phone}
`,
        small_text: `
رقم: ${this.invoice_number}
${moment.utc(this.invoice_date).local().format('dddd DD-MMM-YYYY, hh:mm A')}
الرقم الضريبي: ${this.settings.vat_registration_number}
ــــــــــــــــــــــ
${this.product.name}
${product_details_1}
${product_details_2}
ــــــــــــــــــــــ
الإجمالي: ${total} ريال`,
        small_text_1: `
رقم: ${this.invoice_number}
${moment.utc(this.invoice_date).local().format('dddd DD-MMM-YYYY, hh:mm A')}
الرقم الضريبي: ${this.settings.vat_registration_number}`,
        small_text_2: `
ــــــــــــــــــــــــــــــــــــــــــ
${product_align_details_1}
${product_align_details_2}
ــــــــــــــــــــــــــــــــــــــــــ
الإجمالي شامل الضريبة:   ${total} ريال`,
////////////////////////////////////////////////////////////////////////////
        qr_code_data: {
          invoice_number: this.invoice_number,
          invoice_date_timestamp: this.invoice_date_timestamp,
          vat_registration_number: this.settings.vat_registration_number,
          total_vat: total_vat,
        },
////////////////////////////////////////////////////////////////////////////
        qr_code_text: `
رقم الفاتورة
${this.invoice_number}
\n
${moment.utc(this.invoice_date).local().format('dddd DD-MMM-YYYY, hh:mm A')}
\n
رقم السجل التجاري
${this.settings.commercial_register_number}
رقم تسجيل القيمة المضافة
${this.settings.vat_registration_number}
\n
${this.product.name}
\n
${product_details_1}
${product_details_2}
\n
الإجمالي
${total} ريال
        `,
        qr_code_base64: this.qrCodeBase64(),
      });

      if (this._.has(window, 'JsBridge')) {
        window.JsBridge.printInvoice(JSON.stringify(finalData));
      } else {
        console.log(finalData);
      }
    },
    qrCodeBase64() {
      // this.settings.establishment_name = 'Bobs Records';
      // this.settings.vat_registration_number = '310122393500003';
      // this.invoice_date_timestamp = '2022-04-25T15:30:00Z';
      // this.total = '1000.00';
      // this.totalVat = '150.00';
      // const sellerName = `${this.hex(1)}${this.hex(this.settings.establishment_name.length)}${this.hex(this.settings.establishment_name)}`;
      const sellerName = this.getTlv(1, unescape(encodeURIComponent(this.settings.establishment_name)));
      // const vatNumber = `${this.hex(2)}${this.hex(this.settings.vat_registration_number.length)}${this.hex(this.settings.vat_registration_number)}`;
      const vatNumber = this.getTlv(2, this.settings.vat_registration_number);
      // const timeStamp = `${this.hex(3)}${this.hex(this.invoice_date_timestamp.length)}${this.hex(this.invoice_date_timestamp)}`;
      const timeStamp = this.getTlv(3, this.invoice_date_timestamp);
      // const invoiceAmount = `${this.hex(4)}${this.hex(this.total.length)}${this.hex(this.total)}`;
      const invoiceAmount = this.getTlv(4, this.total);
      // const invoiceVatAmount = `${this.hex(5)}${this.hex(this.totalVat.length)}${this.hex(this.totalVat)}`;
      const invoiceVatAmount = this.getTlv(5, this.totalVat);
      const text = `${sellerName}${vatNumber}${timeStamp}${invoiceAmount}${invoiceVatAmount}`;
      return btoa(text);
    },
    decimalToHex(d, padding) {
      var hex = Number(d).toString(16);
      padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

      while (hex.length < padding) {
        hex = "0" + hex;
      }

      return hex;
    },
    hex(str){
      let result = '';
      for (let i = 0; i < str.length; i++) {
        result += str.charCodeAt(i).toString(16);
      }
      return result.toUpperCase();
    },
    getTlv(tagNum, tagValue) {
      let tagBuf = Buffer.from([tagNum], 'utf8');
      let tagValueLenBuf = Buffer.from([tagValue.length], 'utf8');
      let tagValueBuf = Buffer.from(tagValue, 'utf8');

      return `${tagBuf}${tagValueLenBuf}${tagValueBuf}`;
    }
  },
}
</script>

<style lang="scss">
.wy-top-space {
  height: 40px;
}
.wy-steps-buttons {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: transparent;
  margin-bottom: 10px;
  height: 50px;
  overflow: hidden;
  -webkit-border-bottom-right-radius: 14px;
  -webkit-border-bottom-left-radius: 14px;
  -moz-border-radius-bottomright: 14px;
  -moz-border-radius-bottomleft: 14px;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;

  .wy-back {
    width: 50%;
    background-color: #3462A5;
    color: #FFFFFF;
    height: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    line-height: 50px;
    transition: all 350ms ease;

    [dir=ltr] & {
      float: left;
    }

    [dir=rtl] & {
      float: right;
      border-left: #D9DBDF 1px solid;
    }

    &:active {
      background-color: #4B7EC9;
    }
  }

  .wy-next {
    background-color: #3462A5;
    color: #FFFFFF;
    width: 50%;
    height: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    line-height: 50px;
    transition: all 350ms ease;

    [dir=ltr] & {
      float: right;
    }

    [dir=rtl] & {
      float: left;
    }

    &:active {
      background-color: #4B7EC9;
    }
  }
}

.wy-label {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.wy-customer-step {
  select {
    option {
      font-size: 16px;
    }
  }
}
.wy-product-step {
  .wy-products-list {
    > div {
      box-sizing: content-box;
      cursor: pointer;
      height: 50px;
      margin-bottom: 20px;
      background-color: #FFFFFF;
      border: #CCCCCC 1px dotted;
      transition: all 400ms ease;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      overflow: hidden;
      &.selected {
        border-color: #3462A5;
        background-color: #3462A5;
        color: #FFFFFF;
      }
      span {
        width: 50px;
        height: 50px;
        [dir=ltr] & {
          float: left;
        }

        [dir=rtl] & {
          float: right;
        }
      }
      div {
        height: 50px;
        font-size: 22px;
        line-height: 50px;
        [dir=ltr] & {
          float: left;
          margin-left: 10px;
        }

        [dir=rtl] & {
          float: right;
          margin-right: 10px;
        }
      }
    }
  }
}

.wy-product-quantity-step {
  .wy-quantity {
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
    direction: ltr;
  }
  .wy-keyboard {
    margin: 0 auto;
    width: 270px;
    div {
      width: 90px;
      height: 90px;
      background-color: #FFFFFF;
      border: #EEEEEE 1px solid;
      line-height: 80px;
      font-size: 28px;
      text-align: center;
      cursor: pointer;
      transition: all 200ms ease;
      &:active {
        background-color: #EEEEEE;
      }
      [dir=ltr] & {
        float: left;
      }

      [dir=rtl] & {
        float: left;
      }
    }
  }
}

.wy-payment-method-step {
  .wy-payment-methods-list {
    > div {
      box-sizing: content-box;
      cursor: pointer;
      height: 50px;
      margin-bottom: 20px;
      background-color: #FFFFFF;
      border: #DDDDDD 1px solid;
      transition: all 400ms ease;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      overflow: hidden;
      &.selected {
        border-color: #3462A5;
        background-color: #3462A5;
        color: #FFFFFF;
      }
      > span {
        width: 50px;
        height: 50px;
        text-align: center;
        [dir=ltr] & {
          float: left;
        }

        [dir=rtl] & {
          float: right;
        }

        [class^="wy-icon-"], [class*=" wy-icon-"] {
          font-size: 40px;
          margin: 5px auto 0 auto;
          width: auto;
          height: auto;
          display: block;
        }
      }
      > div {
        height: 50px;
        font-size: 20px;
        line-height: 50px;
        [dir=ltr] & {
          float: left;
          margin-left: 10px;
        }

        [dir=rtl] & {
          float: right;
          margin-right: 10px;
        }
      }
    }
  }
}
.wy-saved-step {
  text-align: center;
  .wy-print-button {
    margin: 20px 5px;
    display: block;
    background-color: #FFFFFF;
    color: #3462A5;
    padding: 30px 20px;
    border: #3462A5 1px dotted;
    font-size: 18px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 400ms ease;
    &:active {
      background-color: #3462A5;
      color: #FFFFFF;
    }
  }
}

.wy-customers-list {
  .wy-customers-list-item {
    background-color: #FFFFFF;
    padding: 14px 5px;
    border: #222222 1px dotted;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 350ms ease;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    &:active {
      border-color: #3462A5;
      background-color: #3462A5;
      color: #FFFFFF;
    }
    &.selected {
      border-color: #3462A5;
      background-color: #3462A5;
      color: #FFFFFF;
    }
  }
}

.wy-search-input-container {
  position: relative;
  margin-bottom: 20px;
  input {
    background-color: #F1F1F1;
    [dir=ltr] & {
      padding-right: 40px;
    }

    [dir=rtl] & {
      padding-left: 40px;
    }
  }
  button.wy-clear-button {
    position: absolute;
    background: none;
    border: none;
    top: 8px;
    color: #C41117;
    transition: color 350ms ease;
    [dir=ltr] & {
      right: 5px;
    }

    [dir=rtl] & {
      left: 5px;
    }
    span {
      margin: 0!important;
      font-size: 24px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      color: #222222;
    }
  }
}
</style>
